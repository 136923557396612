import React, { useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Document = styled.img`
  display: none;
  height: 70px;
  width: fit-content;
  background-color: #000;
  border-radius: 10px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const Description = styled.div`
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  color: ${({ theme }) => theme.text_primary + 99};
  margin-bottom: 10px;
  text-align: center;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    text-align: right;
    padding-right: 10px;
  }
`;

const Span = styled.span`
  overflow: hidden;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const Card = styled.div`
  width: 650px;
  border-radius: 20px;
  padding: 20px;
  justify-content: center;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  transition: all 0.3s ease-in-out;
  background-color: #1e1e1e;
  border: 2px solid #333;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);
  opacity: 0;
  transform: translateY(20px);
  &.visible {
    animation: ${fadeInUp} 0.6s forwards;
  }
  &:hover {
    box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.5);
    transform: translateY(-10px);
  }
  @media only screen and (max-width: 768px) {
    padding: 15px;
    gap: 12px;
    width: 300px;
  }
  &:hover ${Document} {
    display: flex;
  }
  &:hover ${Span} {
    overflow: visible;
    -webkit-line-clamp: unset;
  }
`;

const Top = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

const Image = styled.img`
  height: 50px;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 4px;
  @media only screen and (max-width: 768px) {
    height: 40px;
  }
`;

const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 768px) {
    align-items: flex-end;
  }
`;

const Name = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #e0e0e0;
  text-align: center;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
    text-align: right;
  }
`;

const Degree = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #a0a0a0;
  text-align: center;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    text-align: right;
  }
`;

const Date = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #808080;
  text-align: center;
  @media only screen and (max-width: 768px) {
    font-size: 10px;
    text-align: right;
  }
`;

const Grade = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #a0a0a0;
  text-align: center;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    text-align: right;
  }
`;

const EducationCard = ({ education }) => {
  const cardRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          } else {
            entry.target.classList.remove('visible');
          }
        });
      },
      { threshold: 0.1 }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  return (
    <Card ref={cardRef}>
      <Top>
        <Image src={education.img} />
        <Body>
          <Name>{education.school}</Name>
          <Degree>{education.degree}</Degree>
          <Date>{education.date}</Date>
        </Body>
      </Top>
      <Grade>{education.grade}</Grade>
      <Description>
        <Span>{education.desc}</Span>
      </Description>
    </Card>
  );
};

export default EducationCard;

