import React from 'react';
import { Container, Wrapper, Title, CardContainer } from './ProjectsStyle';
import ProjectCard from '../Cards/ProjectCards';
import { projects } from '../../data/constants';

const Projects = ({ openModal, setOpenModal }) => {
    return (
        <Container id="projects">
            <Wrapper>
                <Title>Projects</Title>
                <CardContainer>
                    {projects
                        .filter((item) => item.category === 'web app')
                        .map((project) => (
                            <ProjectCard key={project.id} project={project} openModal={openModal} setOpenModal={setOpenModal} />
                        ))}
                </CardContainer>
            </Wrapper>
        </Container>
    );
};

export default Projects;
