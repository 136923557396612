export const darkTheme = {
    bg: "#181818", // Very dark gray background
    bgLight: "#242424", // Slightly lighter dark background
    primary: "#1E88E5", // Blue primary color
    text_primary: "#E0E0E0", // Light gray primary text
    text_secondary: "#B0B0B0", // Gray secondary text
    card: "#202020", // Dark card background
    card_light: '#282828', // Slightly lighter card background
    button: "#1E88E5", // Blue button color
    white: "#FFFFFF", // White
    black: "#000000", // Black
}

export const lightTheme = {
    bg: "#F9F9F9", // Light background
    bgLight: "#FFFFFF", // Lighter background
    primary: "#FF6F61", // Coral primary color
    text_primary: "#111111", // Dark primary text
    text_secondary: "#48494A", // Gray secondary text
    card: "#FFFFFF", // White card background
    button: "#FF6F61", // Coral button color
}
