export default {
  // Temp fonts
  fonts: {
    title: "Space Grotesk, sans-serif",
    main: "Space Grotesk, sans-serif"
  },
  // Colors for layout
  colors: {
    primary1: "#1A936F", // Dark green
    background1: "#1F2933", // Dark blue background
    button: "#1A936F", // Dark green button color
    background2: "#0E161C", // Dark secondary background
    text: "#C5D5E6", // Slightly lighter text
    text1: "#E5E9F0", // Lighter variant for text1
    text2: "#6B7E8D", // Darker variant for text2
    text3: "#485664", // Darker variant for text3
    footerBackground: "#000020" // Very dark footer background
  },
  // Breakpoints for responsive design
  breakpoints: {
    sm: 'screen and (max-width: 640px)',
    md: 'screen and (max-width: 768px)',
    lg: 'screen and (max-width: 1024px)',
    xl: 'screen and (max-width: 1280px)'
  },
}
